import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import img1 from '../../assets/assets/images/SAC20-Gala-Landing-Page-header-v01-r11.jpg';

const useStyles = makeStyles((theme) => ({
	videoAreaContainer: {
		padding: '100px 0',
	  [theme.breakpoints.down('sm')]: {
		padding: '50px 0',
	  }
	},
	videoAreaContainerLast: {
		paddingTop: '100px',
		paddingBottom: '0',
		paddingLeft: '0',
		paddingRight: '0',
	  [theme.breakpoints.down('sm')]: {
		paddingTop: '50px',
		paddingBottom: '0',
		paddingLeft: '0',
		paddingRight: '0',
	  }
	},

  }));

export default function Header() {
	const classes = useStyles();
	return (
		<main role="main">
			<section id="mu-video">
				<div class="mu-video-overlay" style={{background: '#fff', opacity: '1'}}>
					<div class="container">
						<div class="row">
							<div class="col-md-12">
								<div class={`mu-video-area ${classes.videoAreaContainer}`}>
									{/* <h2 style={{color: '#ca1f02'}}>Watch The Event</h2> */}
									<div class="embed-responsive embed-responsive-16by9">
										<center>
											<iframe title="Virtual Gala Live" width="1140" height="641.25" src="https://www.youtube.com/embed/gxBo4_wxpsw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
										</center>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="mu-video-content">
					<div class="mu-video-iframe-area">
						<a class="mu-video-close-btn" href="#"><i class="fa fa-times" aria-hidden="true"></i></a>
						<iframe width="854" height="480" src="https://www.youtube.com/embed/od9ucBX96Cs" frameBorder="0" title="Promo Video" allowFullScreen></iframe>
					</div>
				</div>

			</section>
			<section id="mu-about" style={{background: 'linear-gradient(90deg,#fcbd14,#db5725)'}}>
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<div class="mu-about-area">
								<div class="row">
									<div class="col-md-8">
										<div class="mu-about-left">
											<img class="" src={img1} alt="Gala" />
										</div>
									</div>
									<div class="col-md-4">
										<div class="mu-about-right">
											<h2 style={{color: '#fff', fontWeight: '600'}}>RISING <em>TOGETHER</em></h2>

											<p style={{fontSize: '15px', fontWeight: '600', color: '#fff'}}>Throughout 2020 it has become increasingly clear that we are one global family. What affects one affects us all — and during our first-ever virtual gala we will honor the bonds of humanity and hope for a better future that unite us. Together, we will be uplifted through music. Together, we will honor our achievements and celebrate those who make our work possible. Together, we will embolden our spirits and reinvigorate our commitment to others. We will rise.</p>

											<p><a href="https://shareandcare.salsalabs.org/donate/index.html" target="_blank" rel="noopener noreferrer"  class="button button-donateToday">SUPPORT THIS EVENT</a></p>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</section>


 
</main>

)
}