import React, { useState } from 'react';

export default function Home() {
    return (
<footer id="mu-footer" role="contentinfo">
        <div class="container">
            <div class="mu-footer-area">
                <div class="mu-footer-top">
                    <div class="mu-social-media">
                        <a href="http://www.facebook.com/sharer/sharer.php?u=http://shareandcare.org/gala2020&title=Share%20and%20Care%27s%2038th%20Annual%20Gala%20and%20Fundraiser"><i class="fa fa-facebook"></i></a>
                        <a href="http://twitter.com/intent/tweet?status=Share%20and%20Care%27s%2038th%20Annual%20Gala%20and%20Fundraiser+http://shareandcare.org/gala2020"><i class="fa fa-twitter"></i></a>
                        <a href="#"><i class="fa fa-youtube"></i></a>
                    </div>
                </div>
                <div class="mu-footer-bottom">
                    <p class="mu-copy-right" style={{fontSize: '15px', fontWeight: '500'}}>Copyright {`${new Date().getFullYear()}`} Share and Care Foundation®. <span>All rights reserved. <a href="https://shareandcare.org/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. Powered by <a href="https://pridigital.com/" target="_blank">PRI</a>.</span></p>
                </div>
            </div>
        </div>

</footer>
    )
}