import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Logo from '../../assets/img/logo.jpg'
import CardContent from '@material-ui/core/CardContent';
import { useForm } from "react-hook-form";
import MainHome from '../MainHome/MainHome';

const publicIp = require('public-ip');

const axios = require('axios');

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: '0!important',
  },
  paper: {
    // marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    flexDirection: "column",
    justifyContent: "center",
    height: '100vh'
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#ca1f02',
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    fontSize: '15px'
  },
  mtitle: {
    color: '#000',
    fontWeight: 'bold',
    fontFamily: 'Raleway',
    margin: '0 auto',

  },
  msubtitle: {
    color: '#000',
    fontWeight: 'bold',
    fontFamily: 'Raleway',
    margin: '0 auto',
    fontSize: '18px',
    marginTop: '15px'
  }
}));

export default function SignIn(props) {
  const [passwordval, setPassword] = useState('');
  const [err, setErr] = useState(false);
  const [showlogin, setShowLogin] = useState(true);
  const [ip, setIp] = useState('');
  const [errormsg, setErrormsg] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  
  const classes = useStyles();

  useEffect(() => {
    document.body.style.background = 'transparent linear-gradient(90deg, #FCBD14 0%, #DB5725 100%) 0% 0% no-repeat padding-box';
    document.body.style.height = '100vh';
    if (!ip) {
      (async () => {
        setIp(await publicIp.v4());
      //=> '46.5.21.123'
      })();
  }
  })
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    axios.post('https://gala.shareandcare.org/galalogin/login.php', {
      password: passwordval,
      ipaddress: ip,
      fname: fname,
      lname: lname,
      email: email
    })
      .then(function (response) {
        console.log('1', response.data.message);
        if (response.data.success === 0) {
          setErr(true);
          setErrormsg(response.data.message);
        }
        else setShowLogin(false);
      })
      .catch(function (error) {
        console.log('2', error);
      });

  }

  const inputChange = (e) => {
    setPassword(e.target.value);
    setErr(false)
  }

  const loginView = () => {
    return (
      <Container component="main" maxWidth="xs" >
        <CssBaseline />
        <div className={classes.paper}>
          <Card className={classes.root}>
            <CardContent className={classes.root}>
              <div align="center" className={classes.avatar}>
                <img src={Logo} alt="Logo" />
              </div>
              <Typography component="h1" align="center" variant="h5" className={classes.mtitle}>
              Welcome to our 38<sup>th</sup><br />Annual Gala - Rising Together!
        </Typography>
        <Typography component="h2" align="center" variant="h5" className={classes.msubtitle}>
        To view the virtual show on October 10 at 8 PM, please enter your access code (required) and your name and email (optional).
        </Typography>
              <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Access Code"
                  type="text"
                  autoComplete="off"
                  onChange={e => inputChange(e)}
                  value={passwordval}
                  error={err}
                  inputRef={register({ required: true })}
                  helperText={err ? errormsg : ''}
                />
                 <Grid container spacing={2}>
                 <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="fname"
                  label="First Name"
                  type="text"
                  autoComplete="off"
                  onChange={e => setFname(e.target.value)}
                  value={fname}
                />
                </Grid>
                <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="lname"
                  label="Last Name"
                  type="text"
                  autoComplete="off"
                  onChange={e => setLname(e.target.value)}
                  value={lname}
                />
                </Grid>
                </Grid>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="email"
                  label="Email Address"
                  type="email"
                  autoComplete="off"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  ENTER
          </Button>
          <p align="center">Having trouble logging in? Contact us at <a href="mailto:support@shareandcare.org">support@shareandcare.org</a> or <a href="tel:(609) 718-7065">(609) 718-7065</a> for assistance.</p>
                <Grid container>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </div>
      </Container>

    )
  }
  return showlogin ? loginView() : <MainHome />
}