import React, { useEffect } from 'react';
import $ from "jquery";
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../assets/assets/images/logo.jpg'
import '../../assets/assets/styles/bootstrap.min.css'
import '../../assets/assets/styles/slick.css'
import '../../assets/assets/styles/theme-color/sac-2020-theme.css'
import '../../assets/assets/styles/style.min.css';


const useStyles = makeStyles((theme) => ({
  collapsenav: {
    [theme.breakpoints.down('sm')]: {
      background: '#fff',
      marginTop: '-16px'
    }
  },
  mumenu: {
    paddingBottom: '9px!important'
  },
  muevent: {
    fontSize: '17px'
  }
}));

export default function Header() {
  const classes = useStyles();
  
  return (
    <header id="mu-hero" class="" role="banner" style={{background: '#fff'}}>
      <nav class="navbar navbar-fixed-top navbar-default mu-navbar" style={{'backgroundColor': '#fff', borderColor: '#e7e7e7', padding: '5px 0', maxHeight: '75px', top: 'auto', borderBottom: 0}}>
        <div class="container">
          <div class="navbar-header">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar" />
              <span class="icon-bar" />
              <span class="icon-bar" />
            </button>
            <a class="navbar-brand" href="index.html"><img src={Logo} alt="Share and Care" /></a>
          </div>

            <div class={`collapse navbar-collapse ${classes.collapsenav}`} id="bs-example-navbar-collapse-1">
              <ul class={`nav navbar-nav mu-menu navbar-right ${classes.mumenu}`}>
                <li><a id="cta-donate" href="https://shareandcare.salsalabs.org/donate/index.html" target="_blank">Donate Now</a></li>
              </ul>
            </div>
          </div>
            </nav>

        <div class="container">
          <div class="row">
            <div id="logo" class="col-md-12">
              <a class="navbar-brand" href="index.html"><img src={Logo} alt="Share and Care" /></a>
            </div>
          </div>
        </div>

        <div class="mu-hero-overlay">
          <div class="container">
            <div class="mu-hero-area">

              <div class="mu-hero-featured-area">

                <div class="mu-hero-featured-content">

                  <h1>THANK YOU FOR JOINING US.</h1>
                  <h2>You've arrived at the viewing page for our 38th<br />Annual Gala: RISING TOGETHER.</h2>
                  <p class={`mu-event-date-line ${classes.muevent}`}>Saturday, Oct. 10<sup>th</sup> 8:00 PM EST.</p>

                </div>
              </div>
            </div>
          </div>
        </div>
       </header>
    )
}